import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 4×4\\@80% 1RM`}</p>
    <p>{`Band Face Pulls 4×10`}</p>
    <p>{`then,`}</p>
    <p>{`For minutes 1:00 & 2:00:`}</p>
    <p>{`10-Push Press (95/65) `}{`*`}{`from rack`}</p>
    <p>{`10-Ring Rows`}</p>
    <p>{`For minutes 3:00 & 4:00`}</p>
    <p>{`8-Push Press (115/75)`}</p>
    <p>{`8-Ring Rows`}</p>
    <p>{`For minutes 5:00 & 6:00`}</p>
    <p>{`6-Push Press (135/95)`}</p>
    <p>{`6-Ring Rows`}</p>
    <p>{`For minutes 7:00 & 8:00`}</p>
    <p>{`4-Push Press (155/105)`}</p>
    <p>{`4-Ring Rows`}</p>
    <p>{`For minutes 9:00 & 10:00`}</p>
    <p>{`2-Push Press (175/115)`}</p>
    <p>{`2-Ring Rows`}</p>
    <p>{`Score = Total Push Press Reps.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`The CrossFit Open starts February 21st!  **`}</strong>{`Register at:
Games.CrossFit.com.   We’ll be throwing down each Friday night from
4:30-6:30 so the workouts will be Friday’s wod.  Redo’s will be
Saturday’s and Sunday’s after our classes.***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`You can now schedule a massage with Jennifer May, our newest member
and massage therapist! Jennifer is offering a special for the month of
February. You can get a full hour massage for \\$50!! The massage room is
located on the left as soon as you walk into the gym. She works by
appointment only, so email her to schedule.
 `}<a parentName="em" {...{
            "href": "mailto:j.zenmassagetherapy@gmail.com"
          }}>{`j.zenmassagetherapy@gmail.com`}</a>{`.  Sounds like the perfect Valentines
Day gift…`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      